import { toggle } from '@/store/basket';
// import Icon, { AppstoreOutlined, UserOutlined, HistoryOutlined, SnippetsOutlined, ContainerOutlined, ShoppingCartOutlined, LogoutOutlined, HomeOutlined, CarOutlined, SwapOutlined, ApiOutlined } from '@ant-design/icons';
import { Button, Menu, Modal, Tooltip } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useAddTab from '@/hooks/assistant/useAddTab';
import { useWindowDimensions } from '@/hooks/useWindowDimensions';
import { useNavigate } from 'react-router-dom'
import VehicleSelectorModal from '@/components/VehicleSelectorModal';
import SelectRelationModal from '@/components/changeRelation/SelectRelationModal';
import getCatalogName from '@/helpers/getCatalogName';

const HomeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={17}
    viewBox="0 0 19 17"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m11.347.65 5.537 3.872c.845.59 1.366 1.52 1.366 2.534v6.723c0 1.48-1.31 2.56-2.77 2.56H3.02c-1.46 0-2.77-1.08-2.77-2.56V7.056c0-1.013.52-1.943 1.364-2.533L7.154.65a3.71 3.71 0 0 1 4.193 0Zm4.744 5.007-5.538-3.873a2.325 2.325 0 0 0-2.606 0l-5.54 3.873c-.504.353-.773.874-.772 1.398v6.724c0 .581.548 1.175 1.384 1.175h12.462c.836 0 1.384-.594 1.384-1.175V7.056c0-.525-.269-1.046-.774-1.399Z"
      fill={props.Color || "#fff"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.981 10.633a.692.692 0 0 1-.278.939c-1.274.69-2.898 1.017-4.485 1.017-1.586 0-3.21-.327-4.483-1.018a.692.692 0 1 1 .66-1.216c1.02.553 2.403.85 3.823.85s2.804-.297 3.825-.85a.692.692 0 0 1 .938.278Z"
      fill={props.Color || "#fff"}
    />
  </svg>
);

const CatalogIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={19}
    viewBox="0 0 19 19"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.896.267c-.9 0-1.63.73-1.63 1.63v4.072c0 .9.73 1.63 1.63 1.63h4.073c.9 0 1.63-.73 1.63-1.63V1.896c0-.9-.73-1.63-1.63-1.63H1.896Zm-.698 1.63c0-.387.312-.7.698-.7h4.073c.386 0 .698.313.698.7v4.072a.698.698 0 0 1-.698.699H1.896a.698.698 0 0 1-.698-.699V1.896ZM12.564.266c-.9 0-1.63.73-1.63 1.63v4.072c0 .9.73 1.63 1.63 1.63h4.073c.9 0 1.63-.73 1.63-1.63V1.896c0-.9-.73-1.63-1.63-1.63h-4.073Zm-.698 1.63c0-.387.312-.7.698-.7h4.073c.386 0 .698.313.698.7v4.072a.698.698 0 0 1-.698.699h-4.073a.698.698 0 0 1-.698-.699V1.896ZM.266 12.563c0-.9.73-1.63 1.63-1.63h4.073c.9 0 1.63.73 1.63 1.63v4.073c0 .9-.73 1.63-1.63 1.63H1.896c-.9 0-1.63-.73-1.63-1.63v-4.073Zm1.63-.698a.698.698 0 0 0-.698.698v4.073c0 .386.312.698.698.698h4.073a.698.698 0 0 0 .698-.698v-4.073a.698.698 0 0 0-.698-.698H1.896Zm13.17-.466a.465.465 0 1 0-.93 0v2.735H11.4a.465.465 0 1 0 0 .931h2.735V17.8a.465.465 0 1 0 .931 0v-2.735h2.735a.465.465 0 1 0 0-.93h-2.735V11.4Z"
      fill={props.Color || "#fff"}
    />
  </svg>
);

const CarIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={14}
    viewBox="0 0 18 14"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.145.837c-.88 0-1.637.509-1.956 1.193l-.002.002-1.015 2.154h11.656l-1.01-2.142A2.333 2.333 0 0 0 11.79.837H6.145Zm-3.942 3.44-.015.03C.936 4.667 0 5.78 0 7.117v2.51c0 .624.351 1.155.86 1.443v1.104a1.712 1.712 0 0 0 1.708 1.708h1.29a1.712 1.712 0 0 0 1.709-1.708v-.872h7.002v.872a1.713 1.713 0 0 0 1.709 1.708h1.29a1.713 1.713 0 0 0 1.708-1.708v-1.19A1.649 1.649 0 0 0 18 9.629V7.117c0-1.337-.937-2.45-2.19-2.81l-.014-.03h.962a.418.418 0 0 0 0-.838h-1.29a.436.436 0 0 0-.065.005l-.834-1.768-.01-.022A3.168 3.168 0 0 0 11.788 0H6.146C4.95 0 3.89.688 3.43 1.676l-.832 1.765a.172.172 0 0 0-.03 0h-1.29a.419.419 0 1 0 0 .836h.925Zm10.572 6.187h3.487c.514 0 .9-.396.9-.836V7.117c0-1.131-.98-2.094-2.222-2.094H3.06c-1.241 0-2.222.963-2.222 2.094v2.51c0 .44.387.837.902.837h3.477l.866-1.32c.322-.54.998-.772 1.637-.772h2.49c.638 0 1.31.23 1.633.766l.933 1.326Zm-6.558 0h5.535l-.603-.857a.253.253 0 0 1-.02-.03c-.107-.184-.424-.368-.92-.368H7.72c-.495 0-.812.184-.92.368l-.01.019-.572.868Zm7.19 1.71v-.872h3.032v.872a.876.876 0 0 1-.872.871h-1.289a.876.876 0 0 1-.871-.871Zm-8.677-.872H1.697v.872a.876.876 0 0 0 .871.871h1.29a.876.876 0 0 0 .872-.871v-.872ZM2.15 6.438c0-.231.187-.419.418-.419h1.72a.419.419 0 0 1 0 .838h-1.72a.419.419 0 0 1-.418-.419Zm11.663-.419a.419.419 0 1 0 0 .838h1.72a.419.419 0 1 0 0-.838h-1.72Z"
      fill={props.Color || "#fff"}
    />
  </svg>
);

const BasketIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={15}
    viewBox="0 0 18 15"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 .49C0 .22.22 0 .49 0h2.182c.672 0 1.259.455 1.426 1.106l2.074 8.09a.49.49 0 0 0 .475.369h8.105a.49.49 0 0 0 .464-.33l1.776-5.151a.49.49 0 0 0-.464-.65h-8.68a.49.49 0 1 1 0-.981h8.68c1.01 0 1.72.995 1.39 1.951l-1.776 5.15a1.471 1.471 0 0 1-1.39.992H6.647A1.471 1.471 0 0 1 5.222 9.44L3.147 1.35a.49.49 0 0 0-.475-.37H.491A.49.49 0 0 1 0 .49Zm7.288 14.085a1.4 1.4 0 1 0 0-2.803 1.401 1.401 0 1 0 0 2.803Zm8.268-1.401a1.402 1.402 0 1 1-2.803 0 1.402 1.402 0 0 1 2.803 0Z"
      fill={props.Color || "#fff"}
    />
  </svg>
);

const HistoryIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={21}
    viewBox="0 0 19 21"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.421 1.914A9.263 9.263 0 0 1 17.452 4.22a.43.43 0 0 0 .258.132h-2.035a.43.43 0 0 0 0 .86h2.474a.43.43 0 0 0 .439-.43V2.3a.43.43 0 1 0-.86 0v.976a10.123 10.123 0 1 0-.073 14.664.43.43 0 0 0-.59-.626 9.263 9.263 0 1 1-9.644-15.4ZM9.357 5.48a.43.43 0 0 1 .43.43v6.982h2.847a.43.43 0 1 1 0 .861H9.357a.43.43 0 0 1-.43-.43V5.91a.43.43 0 0 1 .43-.43Z"
      fill={props.Color || "#fff"}
    />
  </svg>
);

const ChangeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={16}
    viewBox="0 0 18 16"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 5.887c0-.318.257-.575.574-.575h16.852a.574.574 0 1 1 0 1.15H.573A.574.574 0 0 1 0 5.886Zm18 3.566a.574.574 0 0 1-.575.574H.575a.574.574 0 0 1 0-1.149h16.85c.318 0 .575.257.575.575Z"
      fill={props.Color || "#fff"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.698.168a.574.574 0 0 1 .812 0l5.322 5.322a.574.574 0 1 1-.813.812L11.698.981a.575.575 0 0 1 0-.813ZM6.302 15.171a.575.575 0 0 1-.812 0L.168 9.85a.574.574 0 1 1 .813-.813l5.321 5.322a.574.574 0 0 1 0 .812Z"
      fill={props.Color || "#fff"}
    />
  </svg>
);

const UserIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={16}
    viewBox="0 0 18 16"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.054 4.797c0-.51.102-1.015.301-1.486.199-.471.49-.9.857-1.26a3.96 3.96 0 0 1 1.283-.842 4.017 4.017 0 0 1 3.026 0c.48.195.916.481 1.283.842.367.36.659.789.857 1.26.199.47.301.976.301 1.486 0 1.03-.416 2.017-1.158 2.745A3.991 3.991 0 0 1 9.008 8.68a3.991 3.991 0 0 1-2.796-1.138 3.848 3.848 0 0 1-1.158-2.745ZM9.008 0C6.311 0 4.124 2.147 4.124 4.797c0 2.649 2.186 4.796 4.884 4.796 2.698 0 4.884-2.147 4.884-4.796C13.892 2.147 11.705 0 9.008 0Zm-.015 12.334a12.458 12.458 0 0 1 8.226 3.075.467.467 0 0 0 .516.078.465.465 0 0 0 .24-.264.449.449 0 0 0-.137-.495 13.397 13.397 0 0 0-8.846-3.308 13.396 13.396 0 0 0-8.837 3.33.457.457 0 0 0-.129.491.456.456 0 0 0 .238.26.474.474 0 0 0 .512-.071 12.456 12.456 0 0 1 8.217-3.095Z"
      fill={props.Color || "#fff"}
    />
  </svg>
);

const DocumentIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={19}
    viewBox="0 0 15 19"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.471 0a.4.4 0 0 1 .4.4v4.032c0 .336.273.608.608.608h4.032a.4.4 0 0 1 0 .8H10.48c-.777 0-1.408-.63-1.408-1.408V.4c0-.22.18-.4.4-.4Z"
      fill={props.Color || "#fff"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.416.8C1.523.8.8 1.523.8 2.416v14.111c0 .892.723 1.616 1.616 1.616h10.08c.892 0 1.615-.724 1.615-1.616V5.605L9.306.8h-6.89ZM0 2.416A2.416 2.416 0 0 1 2.416 0H9.47a.4.4 0 0 1 .283.117l5.04 5.04a.4.4 0 0 1 .117.283v11.087a2.416 2.416 0 0 1-2.416 2.416H2.415A2.416 2.416 0 0 1 0 16.527V2.416Z"
      fill={props.Color || "#fff"}
    />
  </svg>
);

const ApiIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={19}
    viewBox="0 0 19 19"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.488.863c.15.15.15.392 0 .541L16.353 3.54c.835 1 .986 2.142.836 3.127-.16 1.06-.672 1.975-1.095 2.398L14.658 10.5a.383.383 0 0 1-.542 0l-.686-.687-1.644 1.644a.383.383 0 0 1-.542-.541l1.644-1.644-2.81-2.81-1.643 1.644a.383.383 0 0 1-.542-.541L9.537 5.92l-.687-.687a.383.383 0 0 1 0-.542l1.437-1.436c.422-.423 1.338-.934 2.397-1.095.985-.15 2.128.002 3.127.836L17.946.863c.15-.15.392-.15.542 0Zm-4.1 8.825 1.165-1.166c.295-.295.74-1.055.88-1.97.135-.896-.027-1.901-.88-2.754-.854-.853-1.859-1.015-2.754-.88-.915.14-1.675.585-1.97.88L9.662 4.963l4.724 4.725ZM4.541 9c.15-.15.392-.15.542 0l5.266 5.266c.15.15.15.392 0 .542l-1.437 1.436c-.423.423-1.338.934-2.397 1.095-.985.15-2.128-.002-3.127-.837l-2.135 2.135a.383.383 0 1 1-.542-.541l2.135-2.135c-.835-1-.986-2.142-.836-3.127.16-1.06.672-1.975 1.095-2.398L4.541 9Zm.27.812L3.648 10.98c-.295.295-.74 1.055-.88 1.97-.135.896.027 1.901.88 2.754s1.859 1.015 2.754.88c.915-.14 1.675-.585 1.97-.88l1.166-1.165-4.724-4.725Z"
      fill={props.Color || "#fff"}
    />
  </svg>
);

const BandenIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.766 9a8.234 8.234 0 1 1 16.468 0A8.234 8.234 0 0 1 .766 9ZM9 0a9 9 0 1 0 0 18A9 9 0 0 0 9 0ZM6.945 4.675A4.769 4.769 0 0 1 9 4.213c.735 0 1.432.166 2.055.462l-1.35 2.34a2.103 2.103 0 0 0-1.409 0l-1.35-2.34Zm-.663.384a4.784 4.784 0 0 0-2.054 3.558h2.7c.09-.485.345-.912.705-1.22L6.283 5.06Zm1.378 3.95a.411.411 0 0 0 0-.018 1.34 1.34 0 1 1 0 .018Zm-.732.374h-2.7a4.784 4.784 0 0 0 2.054 3.558l1.35-2.339a2.104 2.104 0 0 1-.704-1.22Zm1.368 1.603-1.351 2.339c.623.296 1.32.462 2.055.462.736 0 1.432-.166 2.055-.462l-1.35-2.34a2.102 2.102 0 0 1-1.41 0Zm2.072-.384 1.35 2.34a4.784 4.784 0 0 0 2.054-3.56h-2.7a2.104 2.104 0 0 1-.704 1.22Zm.704-1.985h2.7a4.783 4.783 0 0 0-2.054-3.558l-1.35 2.339c.36.307.614.734.704 1.219ZM3.447 9a5.553 5.553 0 1 0 11.106.001A5.553 5.553 0 0 0 3.447 9"
      fill={props.Color || "#fff"}
    />
  </svg>
);

const UniversalIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.884 14.906c.661-1.455 1.1-3.542 1.1-5.906s-.439-4.451-1.1-5.906C10.178 1.54 9.428 1.108 9 1.108c-.428 0-1.178.432-1.884 1.986-.661 1.455-1.1 3.542-1.1 5.906s.439 4.451 1.1 5.906C7.822 16.46 8.572 16.892 9 16.892c.428 0 1.178-.432 1.884-1.986ZM9 18c2.26 0 4.09-4.03 4.09-9S11.26 0 9 0 4.91 4.03 4.91 9 6.74 18 9 18Z"
      fill={props.Color || "#fff"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 16.892a7.894 7.894 0 0 0 7.184-4.62H1.816A7.894 7.894 0 0 0 9 16.893Zm-7.592-5.727h15.184a7.89 7.89 0 0 0 .3-2.165c0-.857-.136-1.682-.389-2.455H1.497A7.886 7.886 0 0 0 1.107 9c0 .75.106 1.477.301 2.165Zm.547-5.727A7.892 7.892 0 0 1 9 1.108a7.892 7.892 0 0 1 7.044 4.33H1.955ZM9 18A9 9 0 1 0 9 0a9 9 0 0 0 0 18Z"
      fill={props.Color || "#fff"}
    />
  </svg>
);

const LogoutIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={24}
    viewBox="0 0 22 24"
    fill="none"
    {...props}
  >
    <path
      stroke={props.Color || "#fff"}
      strokeWidth={1.5}
      strokeLinecap="round"
      d="M11.75 11.662h6.5m-3.625 6.617a7.25 7.25 0 1 1 0-12.558M16 8.882l3 3m-3 3 3-3"
    />
  </svg>
);

const LicensePlateIcon = (props) => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 12C22 15.7713 22 17.6569 20.8284 18.8285C19.6569 20 17.7712 20 14 20H10C6.22876 20 4.34315 20 3.17157 18.8285C2 17.6569 2 15.7713 2 12C2 8.22881 2 6.34319 3.17157 5.17162C4.23467 4.10853 5.8857 4.01009 9 4.00098M15 4.00098C18.1143 4.01009 19.7653 4.10853 20.8284 5.17162C21.4816 5.8248 21.7706 6.69994 21.8985 8.00006"
      stroke={props.Color || "#fff"}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M12 5L12 3"
      stroke={props.Color || "#fff"}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M8 10.5H16"
      stroke={props.Color || "#fff"}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M8 14H13.5"
      stroke={props.Color || "#fff"}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

const WhatsappIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    {...props}
  >
    <path
      d="M9.002 0h-.004C4.035 0 0 4.037 0 9a8.932 8.932 0 0 0 1.713 5.275L.592 17.62l3.46-1.106A8.92 8.92 0 0 0 9.001 18C13.965 18 18 13.962 18 9s-4.035-9-8.998-9Z"
      fill="#4CAF50"
    />
    <path
      d="M14.24 12.71c-.218.612-1.08 1.12-1.767 1.27-.47.1-1.085.18-3.152-.678C6.676 12.206 4.973 9.52 4.84 9.344 4.713 9.17 3.77 7.921 3.77 6.63c0-1.292.656-1.92.92-2.19.217-.222.576-.324.92-.324.112 0 .212.006.302.01.264.012.397.028.571.445.218.523.746 1.815.81 1.947.063.133.128.313.038.487-.085.18-.159.26-.292.413-.133.153-.259.27-.391.435-.122.142-.259.295-.106.56.153.259.682 1.121 1.46 1.814 1.005.895 1.82 1.18 2.11 1.302.218.09.477.069.635-.1.202-.217.45-.577.703-.931.18-.255.408-.286.646-.196.243.084 1.529.72 1.793.851.265.133.44.196.503.307.063.112.063.635-.154 1.25Z"
      fill="#FAFAFA"
    />
  </svg>
);

// const LicensePlateSvg = () => <>
//   <svg
//     width="20px"
//     height="20px"
//     viewBox="0 0 24 24"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <path
//       d="M22 12C22 15.7713 22 17.6569 20.8284 18.8285C19.6569 20 17.7712 20 14 20H10C6.22876 20 4.34315 20 3.17157 18.8285C2 17.6569 2 15.7713 2 12C2 8.22881 2 6.34319 3.17157 5.17162C4.23467 4.10853 5.8857 4.01009 9 4.00098M15 4.00098C18.1143 4.01009 19.7653 4.10853 20.8284 5.17162C21.4816 5.8248 21.7706 6.69994 21.8985 8.00006"
//       stroke="#1C274C"
//       strokeWidth="1.5"
//       strokeLinecap="round"
//     />
//     <path
//       d="M12 5L12 3"
//       stroke="#1C274C"
//       strokeWidth="1.5"
//       strokeLinecap="round"
//     />
//     <path
//       d="M8 10.5H16"
//       stroke="#1C274C"
//       strokeWidth="1.5"
//       strokeLinecap="round"
//     />
//     <path
//       d="M8 14H13.5"
//       stroke="#1C274C"
//       strokeWidth="1.5"
//       strokeLinecap="round"
//     />
//   </svg>
// </>

// const LicensePlateIcon = () => <Icon component={LicensePlateSvg} />

// function BandenSvg() {
//   return (
//     <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
//       width="13.000000pt" height="13.000000pt" viewBox="0 0 50.000000 50.000000"
//       preserveAspectRatio="xMidYMid meet">

//       <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
//         fill="#000000" stroke="none">
//         <path d="M127 483 c-16 -8 -31 -22 -34 -31 -4 -10 -15 -23 -25 -30 -10 -7 -18 -21 -18 -31 0 -9 -5 -22 -10 -27 -16 -16 -22 -182 -8 -207 6 -12 12 -31 13 -41 1 -10 9 -24 18 -31 10 -7 17 -17 17 -22 0 -6 17 -22 38 -37 33 -23 48 -26 119 -26 111 0 160 30 200 120 20 45 24 223 6 241 -7 7 -13 19 -13 27 0 21 -50 82 -81 98 -37 19 -184 17 -222 -3z m86 -35 c-46 -49 -70 -112 -72 -191 -3 -94 27 -173 83 -219 21 -17 20 -18 -20 -18 -25 0 -47 6 -54 15 -7 8 -18 15 -25 15 -15 0 -44 41 -64 90 -16 41 -18 197 -2 213 6 6 11 19 11 29 0 10 7 21 16 25 8 3 20 16 25 29 5 14 17 24 29 24 11 0 20 5 20 10 0 6 19 10 42 10 l42 0 -31 -32z m123 17 c29 -14 74 -65 74 -83 0 -6 6 -22 14 -36 18 -35 13 -169 -9 -219 -19 -47 -66 -94 -100 -103 -19 -5 -37 1 -65 21 -22 15 -40 32 -40 38 0 6 -6 18 -13 26 -26 32 -37 88 -32 161 2 41 9 78 15 84 5 5 10 17 10 26 1 15 43 70 55 70 4 0 13 7 21 15 19 19 33 19 70 0z" />
//         <path d="M253 388 c-36 -40 -47 -72 -47 -138 0 -126 75 -197 147 -140 66 52 66 228 0 280 -34 26 -75 25 -100 -2z m104 -37 c33 -48 33 -154 0 -202 -44 -65 -105 -40 -127 52 -32 133 63 245 127 150z" />
//       </g>
//     </svg>
//   );
// }

// const BandenIcon = () => <Icon component={BandenSvg} />;

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const SideBarMenu = () => {

  const { t } = useTranslation();
  const d = useDispatch();
  const [items, setItems] = useState([]);
  const { width, height } = useWindowDimensions();

  const navigate = useNavigate()

  const [collapsed, setCollapsed] = useState(false);
  const [showSelectedCar, setShowSelectedCar] = useState(false);
  const toggleCollapsed = () => { setCollapsed(!collapsed); };

  const addTab = useAddTab()

  // const router = {}
  const [selectVehicleSettings, setSelectVehicleSettings] = useState({})
  const settings = useSelector(e => e.settings)
  const account = useSelector(e => e.session)

  const [showRelationModal, setShowRelationModal] = useState(false)

  const catalogName = useMemo(() => getCatalogName(), [])

  const role = account?.account?.Role

  const [activeKey, setActiveKey] = useState('home')

  const fixPhone = (e) => e.replaceAll(' ', '').replaceAll('+', '')

  useEffect(() => {
    const keyFromPath = window.location.pathname.split('/')[1];

    const newActiveKey = keyFromPath && typeof keyFromPath === 'string' ? keyFromPath : 'home';

    setActiveKey(newActiveKey);
  }, []);

  const onClickMenu = (key) => {
    // key = key.key

    if (key == 'change-relation') {
      setShowRelationModal(true)
      return
    }

    setActiveKey(key)

    if (key == 'home') {
      navigate('/', null, { shallow: true })
    } else if (key == 'catalog') {
      navigate('/catalog', null, { shallow: true })
    } else if (key == 'cars') {
      setShowSelectedCar(true)
    } else if (key == 'history') {
      // addTab({ type: 'History', title: 'History' })
      navigate('/history')
    } else if (key == 'tires') {
      addTab({ type: 'Tires', title: 'Tires' })
    } else if (key == 'invoices') {
      navigate('/account/invoices')
    } else if (key == 'profile') {
      navigate('/account/profile')
    } else if (key == 'tecdoc-settings') {
      navigate('/tecdoc/menu-create')
    } else if (key == 'license-plate-order') {
      addTab({ type: 'LicensePlateOrder', title: 'LicensePlateOrder' })
    } else if (key == 'basket') {
      d(toggle(true))
    } else if (key == 'whatsapp') {
      window.open('https://wa.me/' + fixPhone(settings.Whatsapp), '_blank').focus();
    } else if (key == 'logout') {
      Modal.confirm({
        title: 'Are you sure you want to log out?',
        onOk: () => {
          navigate('/logout', null, { shallow: true })
        }
      })
    }
  }

  const onSelectVehicle = (e) => { setShowSelectedCar(false) }

  const menuItemStyle = {
    display: 'flex',
    padding: '10px',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    alignSelf: 'stretch',
    borderRadius: '6px',
    background: `${settings['HeaderBgColor']}27`,
    cursor: 'pointer'
  };

  const menuItems = [
    { key: 'home', icon: <HomeIcon Color={settings['HeaderBgColor']} />, tooltip: t('home') },
    { key: 'catalog', icon: <CatalogIcon Color={settings['HeaderBgColor']} />, tooltip: t('catalog') },
    { key: 'cars', icon: <CarIcon Color={settings['HeaderBgColor']} />, tooltip: t('vehicle') },
    // { key: 'basket', icon: <BasketIcon Color={settings['HeaderBgColor']} />, tooltip: t('basket') },
    { key: 'history', icon: <HistoryIcon Color={settings['HeaderBgColor']} />, tooltip: t('history') },
    settings.ShowPlateOrder == 'true' || settings.ShowPlateOrder == true ? { key: 'license-plate-order', icon: <LicensePlateIcon Color={settings['HeaderBgColor']} />, tooltip: t('license-plate-order') } : null,
    settings.ShowTires == 'true' || settings.ShowTires == true ? { key: 'tires', icon: <BandenIcon Color={settings['HeaderBgColor']} />, tooltip: t('tires') } : null,
    catalogName == 'TECDOC' && role == 1 ? { key: 'tecdoc-settings', icon: <ApiIcon Color={settings['HeaderBgColor']} />, tooltip: t('tecdoc-settings') } : null,
    settings.ShowUniversal == 'true' || settings.ShowUniversal == true ? { key: '3', icon: <UniversalIcon Color={settings['HeaderBgColor']} />, tooltip: t('universal') } : null,
    account?.account?.Role == 1 ? { key: 'change-relation', icon: <ChangeIcon Color={settings['HeaderBgColor']} />, tooltip: t('change-relation') } : null,
    { key: 'profile', icon: <UserIcon Color={settings['HeaderBgColor']} />, tooltip: t('profile') },
    { key: 'invoices', icon: <DocumentIcon Color={settings['HeaderBgColor']} />, tooltip: t('invoices') },
  ]

  const menuItemsBottom = [
    { key: 'whatsapp', icon: <WhatsappIcon Color={settings['HeaderBgColor']} />, tooltip: t('whatsapp') },
    { key: 'logout', icon: <LogoutIcon Color={settings['HeaderBgColor']} />, tooltip: t('logout') },
  ];

  function isActive(key) {
    if (!activeKey) return false
    return activeKey === key;
  }

  const menuList = menuItems.map(item => (
    item ? (
      <li
        key={item.key}
        style={menuItemStyle}
        className={`sidebar-menu-item ${isActive(item.key) ? 'active' : ''}`}
        onClick={(e) => onClickMenu(item.key, e)}
      >
        <Tooltip title={item.tooltip} placement="right">
          {item.icon}
          <span>{item.label}</span>
        </Tooltip>
      </li>
    ) : null
  ));

  const menuListBottom = menuItemsBottom.map(item => (
    item ? (
      <li
        key={item.key}
        style={menuItemStyle}
        className={`sidebar-menu-item ${isActive(item.key) ? 'active' : ''}`}
        onClick={(e) => onClickMenu(item.key, e)}
      >
        <Tooltip title={item.tooltip} placement="right">
          {item.icon}
          <span>{item.label}</span>
        </Tooltip>
      </li>
    ) : null
  ));

  return (
    <div className='main-sidebar-menu-container' >
      <div style={{ paddingTop: "40px" }}>
        <Modal title={t('select-vehicle')} {...selectVehicleSettings} footer={null} open={showSelectedCar} onCancel={() => setShowSelectedCar(false)}>
          <VehicleSelectorModal onSelectMotor={onSelectVehicle} />
        </Modal>

        <SelectRelationModal setVisible={setShowRelationModal} visible={showRelationModal} onCancel={() => setShowRelationModal(false)} />

        <ul style={{
          background: '#fff',
          padding: '10px',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          borderRadius: '16px',
          boxShadow: '0px 0px 15px 0px rgba(149, 157, 165, 0.12)',
        }}>
          {menuList}
        </ul>

        {/* <Menu
          onClick={onClickMenu}
          defaultSelectedKeys={['1']}
          mode={width > 1024 ? "inline" : "horizontal"}

          inlineCollapsed={true}
          items={width > 1024 ? [
            getItem(t('home'), 'home', <HomeIcon />),
            getItem(t('catalog'), 'catalog', <CatalogIcon />),
            getItem(t('vehicle'), 'cars', <CarIcon />),

            // getItem(t('basket'), 'basket', <ShoppingCartOutlined />),
            getItem(t('history'), 'history', <HistoryIcon />),

            settings.ShowPlateOrder == 'true' || settings.ShowPlateOrder == true ? getItem(t('license-plate-order'), 'license-plate-order', <LicensePlateIcon />) : null,
            settings.ShowTires == 'true' || settings.ShowTires == true ? getItem(t('tires'), 'tires', <BandenIcon />) : null,
            catalogName == 'TECDOC' && role == 1 ? getItem(t('tecdoc-settings'), 'tecdoc-settings', <ApiIcon />) : null,

            // settings.ShowUniversal == 'true' || settings.ShowUniversal == true ? getItem(t('universal'), '3', <ContainerOutlined />) : null,
            account?.account?.Role == 1 ? getItem(t('change-relation'), 'change-relation', <ChangeIcon />) : null,
            getItem(t('profile'), 'profile', <UserIcon />),
            getItem(t('invoices'), 'invoices', <DocumentIcon />),
          ] : [
            getItem("", 'home', <HomeIcon />),
            getItem("", 'catalog', <CatalogIcon />),
            getItem("", 'cars', <CarIcon />),
            getItem("", 'basket', <BasketIcon />),
            getItem("", 'history', <HistoryIcon />),
            settings.Tires == 'true' || settings.Tires == true ? getItem("", 'tires', <BandenIcon />) : null,
            settings.ShowUniversal == 'true' || settings.ShowUniversal == true ? getItem("", '3', <UniversalIcon />) : null,
            getItem("", '4', <UserIcon />),
            getItem("", '5', <DocumentIcon />),
            // getItem("", 'logout', <LogoutIcon />),
          ]}
        /> */}
      </div>
      <div>
        <ul style={{
          background: '#fff',
          padding: '10px',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          borderRadius: '16px',
          boxShadow: '0px 0px 15px 0px rgba(149, 157, 165, 0.12)',
        }}>
          {menuListBottom}
        </ul>

        {/* <Menu
          onClick={onClickMenu}
          defaultSelectedKeys={['1']}
          mode={width > 1024 ? "inline" : "horizontal"}
          inlineCollapsed={true}
          items={width > 1024 ? [
            getItem("", 'logout', <LogoutIcon />),
          ] : [
            getItem("", 'logout', <LogoutIcon />),
          ]}
        /> */}
      </div>
    </div>
  );
};

export default SideBarMenu;
