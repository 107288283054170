import api from '@/services/api';
export default ({plate,seriesCode,lineCode}) => {
    let query = plate ? `?plate=${plate}` : seriesCode ? `?seriesCode=${seriesCode}` : lineCode ? `?lineCode=${lineCode}` : ""

        return new Promise((resolve, reject) => {

            api()({
                url: "/catalog/vehicle-info" + query,
            })
                .then((res) => {
                    if (res.data.status == true) {
                        resolve(res.data.data)
                    }
                    reject(false)

                })
                .catch((e) => { 
                    reject(false) })
        })
}