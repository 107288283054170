import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { RightOutlined } from '@ant-design/icons'
import { Divider, Image, message, Modal, Skeleton, Spin, Table } from 'antd';
import api from '@/services/api';

import useAddTab from '@/hooks/assistant/useAddTab';

const ResultVinnNumberModal = ({ vinn,cb }) => {


    const [loading, setLoading] = React.useState(false);
    const { t } = useTranslation();
    const [data, setData] = React.useState([])
    const addTab = useAddTab();
    useEffect(() => {
        if (vinn) {
            setLoading(true)
            api().post('/catalog/search-vinn-number', {
                Vinn: vinn
            }).then(e => {
                setData(e.data.data.sort((a, b) => a?.LineName?.localeCompare(b?.LineName)))
                if (e.data.data.length == 1) {
                    let item = e.data.data[0];
                    addTab({
                        vehicleLineId: item.LineCode,
                        vehicleLineLineName: item.LineName,
                        section: "vehicleMenu",
                        make: item.MakeCode,
                        plate: null,
                        makeName: item.VehicleMakeName,
                        type: "assistant",
                        vehicleSeriesName: item.VehicleReferenceName,
                        vehicleSeriesId: item.VehicleReferenceId,
                        title: item.VehicleMakeName + " " + item.LineName + " " + item.VehicleReferenceName
                    }, 'redirect')
                    cb();
                }

            }).catch(f => {
                message.error(t('vinn-not-found'))
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [vinn])


    const SelectArea = ({ children, item }) => {
        const onRowClick = () => {
            api().post('/catalog/validate-type-code', {
                Vinn: vinn,
                SeriesId : item.VehicleReferenceId
            })
            addTab({
                vehicleLineId: item.LineCode,
                vehicleLineLineName: item.LineName,
                section: "vehicleMenu",
                make: item.MakeCode,
                plate: null,
                makeName: item.VehicleMakeName,
                type: "assistant",
                vehicleSeriesName: item.VehicleReferenceName,
                vehicleSeriesId: item.VehicleReferenceId,
                title: item.VehicleMakeName + " " + item.LineName + " " + item.VehicleReferenceName
            }, 'redirect')
            cb();
        }
        return (<div onClick={() => {
            onRowClick(item)
        }} style={{
            cursor: 'pointer'
        }}>
            {children}
        </div>)
    }



    const columns = [
        {
            title: 'Make',
            dataIndex: 'Make',
            key: 'Make',
            render: (a, b) => {
                return <SelectArea item={b} >{a}</SelectArea>
            }
        },

        {
            title: 'LineName',
            dataIndex: 'LineName',
            key: 'LineName',
            render: (a, b) => {
                return <SelectArea item={b} >{a}</SelectArea>
            }
        },
        {
            title: 'EngineType',
            dataIndex: 'EngineType',
            key: 'EngineType',
            render: (a, b) => {
                return <SelectArea item={b} >{a}</SelectArea>
            }
        },
        {
            title: 'VehicleReferenceName',
            dataIndex: 'VehicleReferenceName',
            key: 'VehicleReferenceName',
            render: (a, b) => {
                return <SelectArea item={b} >{a}</SelectArea>
            }

        },

        {
            title: 'Image',
            dataIndex: 'Image',
            key: 'Image',
            render: (a, b) => {
                return <SelectArea item={b} ><img src={"https://material.parts-soft.net/line.php?lineId=" + b?.LineCode} style={{
                    height: '40px'
                }} /></SelectArea>
            }
        }


    ];

    return (<div>


        <Skeleton loading={loading} active={true}>
            <Table dataSource={data} columns={columns} />
        </Skeleton>



    </div>)
}


const ResultVinnNumber = ({ reducer,text = "VINN" }) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = React.useState(false);
    return (
        <>

            <Modal width={'100%'} title={"VINN Number"} footer={null} zIndex={9999999} open={showModal} onCancel={() => setShowModal(false)} >
                <ResultVinnNumberModal cb={()=>setShowModal(false)} key={reducer.state.text} vinn={reducer.state.vinnNumber} />
            </Modal>

            <div className='flex'>
                <p style={{ color: "#A5A5A5", fontSize: "20px" }}>{t(text)}</p>
                <div className="flex items-center cursor-pointer" onClick={() => setShowModal(true)}>
                    {<RightOutlined />}
                    {<p style={{ fontWeight: 500, fontSize: "13px", padding: 0 }} className="ml-2">
                        <a style={{ color: "#2b94c8" }}>{reducer.state.vinnNumber?.toUpperCase()}</a>
                    </p>}
                </div>


            </div>
            <Divider />
        </>
    )
}

export default ResultVinnNumber