import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    loadingRouter : false,
    loadingPercent : 0,
    showMobileSearch : false,
    showMobileSidebar:false,
    pageLoading:false,
    loadingCatalogPage : false,

}

export const site = createSlice({
    name: 'site',
    initialState,
    reducers: {
      setLoadingRouter : (state,action) => {
        state.loadingRouter = action.payload
      },
      setLoadingPercent : (state,action) => {
        state.loadingPercent = action.payload
      },
      setShowMobileSidebar : (state,action) => {
        state.showMobileSidebar = action.payload
      },
      setPageLoading : (state,action) => {
        state.pageLoading = action.payload
      },
      
      setShowMobileSearch : (state,action) => {
        state.showMobileSearch = action.payload
      },
      setLoadingCatalogPage : (state,action) => {
        state.loadingCatalogPage = action.payload
      }
    },
  })
  
  // Action creators are generated for each case reducer function
  export const { setLoadingCatalogPage,setShowMobileSearch,setLoadingRouter,setLoadingPercent,setShowMobileSidebar,setPageLoading} = site.actions
  
  export default site.reducer
  
