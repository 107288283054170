//Parametre olarak seriesId veya lineId veya plate  classı alır.
import React, { useEffect, useState } from "react";
import { Card, Image, Modal, Skeleton } from "antd";
import fetchVehicleInfo from "@/core/vehicle/fetchVehicleInfo";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import RdwSection from "./RdwSection";

const clearPlate = (Plate) => {
  try {
    return Plate.toUpperCase().replaceAll(' ', '').replaceAll('-', '');
  } catch (e) {
    return ""
  }
}

const RdwLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={98}
    height={33}
    viewBox="0 0 98 33"
    fill="none"
    {...props}
  >
    <path
      d="m50.3 11.733-9.117-4.501h-.002c-.666.085-1.423.201-2.04.306l6.202 2.689c-.175.076-.245.1-.395.164l-6.604-2.71a48.992 48.992 0 0 0-3.604.802l5.93 1.918c-.275.127-.349.162-.633.295l-6.412-1.921c-1.906.532-3.859 1.171-6.45 2.23h.002l12.068 2.11c-.34.179-.545.296-.886.482l-12.845-1.882c-3.061 1.304-6.611 3.306-9.439 5.153h.002l12.64.003h.002c-.578.366-1.067.671-1.757 1.154l.012-.01-13.226.443.003.002c-2.43 1.715-4.908 3.77-6.348 5.04C6.007 24.732 1.885 28.91 0 32.668l15.424-4.014.002.002c-.694 1.023-1.258 2.088-1.591 3.038l16.751-5.307c-.175-3.908 3.618-7.398 6.196-9.516h2.126c-3.297 2.792-4.82 5.925-4.391 8.283l5.243-1.661c-1.288-3.24 2.163-9.001 10.54-11.76Zm-3.479-.571 1.025.422a36.276 36.276 0 0 0-4.62 2.22l-1.408-.23c1.257-.74 3.106-1.636 5.003-2.412Z"
      fill="#E46F26"
    />
    <path
      d="M61.408 6.048v4.472l1.506.606v.433h-4.676v-.433l1.505-.606V1.04L58.238.433V0h4.8c1.417 0 1.947.138 2.604.64.654.503 1.008 1.249 1.008 2.133 0 1.56-1.061 2.686-2.815 2.964l3.56 4.765 1.364.624v.433h-2.833l-3.88-5.511h-.638Zm0-.692h1.186c1.311 0 2.196-.989 2.196-2.427 0-1.421-.78-2.235-2.143-2.235h-1.239v4.662Zm7.49 6.204v-.433l1.506-.606v-9.48L68.9.434V0h5.294c2.285 0 3.472.416 4.535 1.578.939 1.04 1.38 2.321 1.38 3.95 0 2.098-.778 3.848-2.195 4.922-1.027.78-2.231 1.109-3.985 1.109h-5.03v.001Zm3.171-.78h1.7c1.523 0 2.338-.295 3.205-1.179.956-.988 1.381-2.202 1.381-3.9 0-1.455-.318-2.564-.991-3.448C76.566 1.196 75.592.78 73.98.78h-1.913v10h.001Zm16.774-8.077-3.258 9.029h-.727L81.282 1.04 79.918.433V0h4.48v.433l-1.346.606 2.055 6.515c.088.243.14.468.212.676.089.365.141.554.141.606.035.069.07.191.125.33L88.843 0h.798l2.515 7.608c.283.797.354 1.091.46 1.664l2.797-8.232-1.362-.607V0H98v.433l-1.382.606-4.002 10.693h-.726l-3.048-9.03Z"
      fill="#000"
    />
  </svg>
);


const VehicleInfo = ({ className, Series, Line, Plate, Make, MakePhoto }) => {
  const [info, setInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { t } = useTranslation();
  const [showRdwModal, setShowRdwModal] = useState(false)

  let color = useSelector((e) => e.settings.HeaderBgColor);

  useEffect(() => {

    if (Make) {
      info.Image = MakePhoto;
      info.MakeName = "";
      info.Line = "";
      info.Series = "";
    } else {
      setLoading(true)
      fetchVehicleInfo({ plate: Plate, lineCode: Line, seriesCode: Series }).then(res => {
        setInfo(res)
      }).catch(err => { }).finally(_ => setLoading(false))

    }


  }, [Series, Line, Plate, Make]);


  const onClickRdwHandler = () => {
    setShowRdwModal(true)
  }

  return (
    <>
      {loading ? (
        <Card>
          <Skeleton loading={loading} active={true}></Skeleton>
        </Card>
      ) : (
        <div className="mb-[10px]">

          <Modal footer={null} width={'100%'} open={showRdwModal} onCancel={() => setShowRdwModal(false)} onOk={() => setShowRdwModal(false)}>
            <RdwSection plate={Plate} />
          </Modal>


          {/* <div className="car-information__container">
            <div className="car-information__container__image">
              <Image style={{ height: '100px' }} src={info.Image} />
            </div>
            <div className="car-information__header">
              <div className="tag-3">
                <span>{info.MakeName + " " + info.Line + " " + (info.Series ? info.Series : "")}</span>
              </div>

              {Plate ? (
                <div className="car-information__header__plate">
                  <div className="car-information__header__plate__start">
                    <p>NL</p>
                  </div>
                  <div className="car-information__header__plate__content">
                    <p>{clearPlate(Plate)}</p>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="car-information__container__description">
              <div className="car-information__container__description__group">
                <div className="car-information__container__description__group__item">
                  <div className="car-information__container__description__group__item__text">
                    <p>{t('brand')}</p>
                  </div>
                  <div className="car-information__container__description__group__item__title">
                    <p>{info.MakeName ? info.MakeName.toUpperCase() : ""}</p>
                  </div>
                </div>
                <div className="car-information__container__description__group__item">
                  <div className="car-information__container__description__group__item__text">
                    <p>{t('model')}</p>
                  </div>
                  <div className="car-information__container__description__group__item__title">
                    <p>{info.Line}</p>
                  </div>
                </div>
                <div className="car-information__container__description__group__item">
                  <div className="car-information__container__description__group__item__text">
                    <p>{t('type')}</p>
                  </div>
                  <div className="car-information__container__description__group__item__title">
                    <p>{info.Series}</p>
                  </div>
                </div>

                <div className="car-information__container__description__group__item">
                  <div className="car-information__container__description__group__item__text">
                    <p>{t('kleur')}</p>
                  </div>
                  <div className="car-information__container__description__group__item__title">
                    <p></p>
                  </div>
                </div>
                <div className="car-information__container__description__group__item">
                  <div className="car-information__container__description__group__item__text">
                    <p>{t('motor-code')}</p>
                  </div>
                  <div className="car-information__container__description__group__item__title">
                    <p>{info.EngineType}</p>
                  </div>
                </div>
                <div className="car-information__container__description__group__item">
                  <div className="car-information__container__description__group__item__text">
                    <p>{t('year')}</p>
                  </div>
                  <div className="car-information__container__description__group__item__title">
                    <p>{info.VehicleReferenceName}</p>
                  </div>
                </div>

                <div className="car-information__container__description__group__item">
                  <div className="car-information__container__description__group__item__text">
                    <p>{t('horse-power')}</p>
                  </div>
                  <div className="car-information__container__description__group__item__title">
                    <p>{info.HorsePower}</p>
                  </div>
                </div>
              </div>
              <div className="car-information__container__description__group">
                <div className="car-information__container__description__group__item">
                  <div className="car-information__container__description__group__item__text">
                    <p>{t('body-type')}</p>
                  </div>
                  <div className="car-information__container__description__group__item__title">
                    <p>{info.BodyType}</p>
                  </div>
                </div>
                <div className="car-information__container__description__group__item">
                  <div className="car-information__container__description__group__item__text">
                    <p>{t('fuel-code')}</p>
                  </div>
                  <div className="car-information__container__description__group__item__title">
                    <p>{info.FuelCode}</p>
                  </div>
                </div>
                <div className="car-information__container__description__group__item">
                  <div className="car-information__container__description__group__item__text">
                    <p>{t('build-start')}</p>
                  </div>
                  <div className="car-information__container__description__group__item__title">
                    <p>{info.BuildStart}</p>
                  </div>
                </div>

                <div className="car-information__container__description__group__item">
                  <div className="car-information__container__description__group__item__text">
                    <p>{t('build-end')}</p>
                  </div>
                  <div className="car-information__container__description__group__item__title">
                    <p>{info.BuildEnd}</p>
                  </div>
                </div>
                <div className="car-information__container__description__group__item">
                  <div className="car-information__container__description__group__item__text">
                    <p>{t('line-start')}</p>
                  </div>
                  <div className="car-information__container__description__group__item__title">
                    <p>{info.LineStart}</p>
                  </div>
                </div>
                <div className="car-information__container__description__group__item">
                  <div className="car-information__container__description__group__item__text">
                    <p>{t('line-end')}</p>
                  </div>
                  <div className="car-information__container__description__group__item__title">
                    <p>{info.LineEnd}</p>
                  </div>
                </div>

                {info?.Apk_End_End && <div className="car-information__container__description__group__item">
                  <div className="car-information__container__description__group__item__text">
                    <p>{t('apk-end')}</p>
                  </div>
                  <div className="car-information__container__description__group__item__title">
                    <p>{info.Apk_End}</p>
                  </div>
                </div>}

                {info?.Color && <div className="car-information__container__description__group__item">
                  <div className="car-information__container__description__group__item__text">
                    <p>{t('color')}</p>
                  </div>
                  <div className="car-information__container__description__group__item__title">
                    <p>{info.Color}</p>
                  </div>
                </div>}
              </div>
            </div>



            {Plate && <div onClick={onClickRdwHandler} className="cursor-pointer absolute right-[20px] top-[20px]">
              <img width={100} height={100} src="https://webshop.parts-soft.eu/rdw-logo.png" />
            </div>}

            <div className="car-information__container__logo">
              <img style={{ height: "80px" }} src={info.Logo} />
            </div>
          </div> */}

          {/* <div className="flex items-start justify-between relative w-full">
            <div className="inline-flex items-start gap-[10px] relative flex-[0_0_auto]">
              <div className="w-[20%]">
                <Image className="w-full h-[170px] relative object-cover relative object-scale-down self-stretch grow shrink basis-0 hoverZoomLink" src={info.Image} />
              </div>
              <div className="inline-flex flex-col items-start relative flex-[0_0_auto] w-[60%]">
                <div className="flex items-start px-[10px] py-[5px] relative self-stretch w-full flex-[0_0_auto]">
                  <p className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Bold',Helvetica] font-bold text-[#0f5fc7] text-[18px] tracking-[0] leading-[normal]">
                    Audi A6 Avant Quattro (4F5) 3.0 TDI V6 24_V
                  </p>
                </div>
                <div className="inline-flex items-start gap-[10px] p-[10px] relative flex-[0_0_auto]">
                  <div className="inline-flex flex-col items-start gap-[5px] relative flex-[0_0_auto]">
                    <div className="flex w-[230px] h-[22px] items-start justify-between px-[5px] py-[2px] relative bg-[#f6f7f9] rounded-[4px] overflow-hidden">
                      <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-medium text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                        Merk :
                      </div>
                      <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                        FORD
                      </div>
                    </div>
                    <div className="flex w-[230px] h-[22px] justify-between px-[5px] py-[2px] rounded-[4px] overflow-hidden items-start relative">
                      <div className="relative w-[96px] mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-medium text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                        Model :
                      </div>
                      <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                        Ka I
                      </div>
                    </div>
                    <div className="flex w-[230px] h-[22px] items-start justify-between px-[5px] py-[2px] relative bg-[#f6f7f9] rounded-[4px] overflow-hidden">
                      <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-medium text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                        Type :
                      </div>
                      <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                        1.3i
                      </div>
                    </div>
                    <div className="flex w-[230px] h-[22px] justify-between px-[5px] py-[2px] rounded-[4px] overflow-hidden items-start relative">
                      <div className="relative w-[96px] mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-medium text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                        Motorcode :
                      </div>
                      <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                        J4D
                      </div>
                    </div>
                    <div className="flex w-[230px] h-[22px] items-start justify-between px-[5px] py-[2px] relative bg-[#f6f7f9] rounded-[4px] overflow-hidden">
                      <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-medium text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                        Paardenkracht :
                      </div>
                      <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                        59
                      </div>
                    </div>
                    <div className="flex w-[230px] h-[22px] justify-between px-[5px] py-[2px] rounded-[4px] overflow-hidden items-start relative">
                      <div className="relative w-[96px] mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-medium text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                        Motorcode :
                      </div>
                      <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                        J4D
                      </div>
                    </div>
                  </div>
                  <div className="inline-flex flex-col gap-[5px] self-stretch flex-[0_0_auto] items-start relative">
                    <div className="flex w-[230px] h-[22px] items-start justify-between px-[5px] py-[2px] relative bg-[#f6f7f9] rounded-[4px] overflow-hidden">
                      <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-medium text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                        Caarrosserietype :
                      </div>
                      <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                        HB
                      </div>
                    </div>
                    <div className="flex w-[230px] h-[22px] justify-between px-[5px] py-[2px] rounded-[4px] overflow-hidden items-start relative">
                      <div className="relative w-[96px] mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-medium text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                        Brandstofcode :
                      </div>
                      <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                        4
                      </div>
                    </div>
                    <div className="flex w-[230px] h-[22px] items-start justify-between px-[5px] py-[2px] relative bg-[#f6f7f9] rounded-[4px] overflow-hidden">
                      <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-medium text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                        Start met bouwen :
                      </div>
                      <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                        1996-09-01
                      </div>
                    </div>
                    <div className="flex w-[230px] h-[22px] justify-between px-[5px] py-[2px] rounded-[4px] overflow-hidden items-start relative">
                      <div className="relative w-[96px] mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-medium text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                        Einde bouwen :
                      </div>
                      <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                        1996-09-01
                      </div>
                    </div>
                    <div className="flex w-[230px] h-[22px] items-start justify-between px-[5px] py-[2px] relative bg-[#f6f7f9] rounded-[4px] overflow-hidden">
                      <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-medium text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                        Start lijn :
                      </div>
                      <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                        1996-09-01
                      </div>
                    </div>
                    <div className="flex w-[230px] h-[22px] justify-between px-[5px] py-[2px] rounded-[4px] overflow-hidden items-start relative">
                      <div className="relative w-[96px] mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-medium text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                        Einde Lijn :
                      </div>
                      <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                        1996-09-01
                      </div>
                    </div>
                  </div>
                  <div className="inline-flex flex-col gap-[5px] self-stretch flex-[0_0_auto] items-start relative">
                    <div className="flex w-[230px] h-[22px] items-start justify-between px-[5px] py-[2px] relative bg-[#f6f7f9] rounded-[4px] overflow-hidden">
                      <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-medium text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                        Caarrosserietype :
                      </div>
                      <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                        HB
                      </div>
                    </div>
                    <div className="flex w-[230px] h-[22px] justify-between px-[5px] py-[2px] rounded-[4px] overflow-hidden items-start relative">
                      <div className="relative w-[96px] mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-medium text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                        Brandstofcode :
                      </div>
                      <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                        4
                      </div>
                    </div>
                    <div className="flex w-[230px] h-[22px] items-start justify-between px-[5px] py-[2px] relative bg-[#f6f7f9] rounded-[4px] overflow-hidden">
                      <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-medium text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                        Start met bouwen :
                      </div>
                      <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                        1996-09-01
                      </div>
                    </div>
                    <div className="flex w-[230px] h-[22px] justify-between px-[5px] py-[2px] rounded-[4px] overflow-hidden items-start relative">
                      <div className="relative w-[96px] mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-medium text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                        Einde bouwen :
                      </div>
                      <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                        1996-09-01
                      </div>
                    </div>
                    <div className="flex w-[230px] h-[22px] items-start justify-between px-[5px] py-[2px] relative bg-[#f6f7f9] rounded-[4px] overflow-hidden">
                      <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-medium text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                        Start lijn :
                      </div>
                      <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                        1996-09-01
                      </div>
                    </div>
                    <div className="flex w-[230px] h-[22px] justify-between px-[5px] py-[2px] rounded-[4px] overflow-hidden items-start relative">
                      <div className="relative w-[96px] mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-medium text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                        Einde Lijn :
                      </div>
                      <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                        1996-09-01
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="inline-flex flex-col items-start justify-between relative flex-[0_0_auto]">
              <div className="flex items-start justify-between p-[10px] relative self-stretch w-full flex-[0_0_auto]">
                <div className="inline-flex flex-col items-start p-[5px] relative flex-[0_0_auto] bg-[#1359b1] rounded-[3px_0px_0px_3px] overflow-hidden">
                  <div className="relative w-[19.99px] h-[24px] mt-[-1.00px] [font-family:'Poppins-Bold',Helvetica] font-bold text-white text-[16px] tracking-[0] leading-[24px] whitespace-nowrap">
                    NL
                  </div>
                </div>
                <div className="inline-flex flex-col items-center p-[5px] relative flex-[0_0_auto] bg-[#ffc501] rounded-[0px_3px_3px_0px] overflow-hidden">
                  <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Bold',Helvetica] font-bold text-[#333333] text-[16px] tracking-[0] leading-[normal]">
                    19FZDV
                  </div>
                </div>
              </div>

              <img className="relative self-stretch w-full flex-[0_0_auto]" style={{ height: "80px" }} src={info.Logo} />
              <RdwLogo />
            </div>
          </div> */}

          <div className="flex flex-col md:flex-row justify-between relative rounded-[6px] border border-neutral-200 ">
            <div className="md:w-1/6 p-[10px] w-full flex items-center">
              <Image className="w-full object-cover object-scale-down hover:zoom" src={info.Image} />
            </div>

            <div className="md:w-4/6 flex flex-col items-start p-[10px] h-full">
              <div className="flex items-start w-full">
                <p className="font-bold text-[18px]" style={{ color: color }}>
                  {info.MakeName + " " + info.Line + " " + (info.Series ? info.Series : "")}
                </p>
              </div>
              <div className="md:flex md:gap-[10px] w-full">
                <div className="inline-flex flex-col items-start gap-[5px] relative flex-[0_0_auto] md:w-1/3 w-full">
                  <div className="flex w-full items-start justify-between px-[5px] py-[5px] relative bg-[#f6f7f9] rounded-[4px] overflow-hidden">
                    <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-medium text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                      {t('brand').replace(/./, x => x.toUpperCase())}
                    </div>
                    <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                      {info.MakeName ? info.MakeName.toUpperCase() : ""}
                    </div>
                  </div>
                  <div className="flex w-full justify-between px-[5px] py-[5px] rounded-[4px] overflow-hidden items-start relative">
                    <div className="relative w-[96px] mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-medium text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                      {t('model').replace(/./, x => x.toUpperCase())}
                    </div>
                    <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                      {info.Line}
                    </div>
                  </div>
                  <div className="flex w-full items-start justify-between px-[5px] py-[5px] relative bg-[#f6f7f9] rounded-[4px] overflow-hidden">
                    <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-medium text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                      {t('type').replace(/./, x => x.toUpperCase())}
                    </div>
                    <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                      {info.Series}
                    </div>
                  </div>
                  <div className="flex w-full justify-between px-[5px] py-[5px] rounded-[4px] overflow-hidden items-start relative">
                    <div className="relative w-[96px] mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-medium text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                      {t('kleur').replace(/./, x => x.toUpperCase())}
                    </div>
                    <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">

                    </div>
                  </div>
                  <div className="flex w-full items-start justify-between px-[5px] py-[5px] relative bg-[#f6f7f9] rounded-[4px] overflow-hidden">
                    <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-medium text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                      {t('motor-code').replace(/./, x => x.toUpperCase())}
                    </div>
                    <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                      {info.EngineType}
                    </div>
                  </div>

                </div>
                <div className="inline-flex flex-col items-start gap-[5px] relative flex-[0_0_auto] md:w-1/3 w-full">
                  <div className="flex w-full items-start justify-between px-[5px] py-[5px] relative bg-[#f6f7f9] rounded-[4px] overflow-hidden">
                    <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-medium text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                      {t('year').replace(/./, x => x.toUpperCase())}
                    </div>
                    <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                      {info.VehicleReferenceName}
                    </div>
                  </div>
                  <div className="flex w-full justify-between px-[5px] py-[5px] rounded-[4px] overflow-hidden items-start relative">
                    <div className="relative w-[96px] mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-medium text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                      {t('horse-power').replace(/./, x => x.toUpperCase())}
                    </div>
                    <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                      {info.HorsePower}
                    </div>
                  </div>
                  <div className="flex w-full items-start justify-between px-[5px] py-[5px] relative bg-[#f6f7f9] rounded-[4px] overflow-hidden">
                    <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-medium text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                      {t('body-type').replace(/./, x => x.toUpperCase())}
                    </div>
                    <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                      {info.BodyType}
                    </div>
                  </div>
                  <div className="flex w-full justify-between px-[5px] py-[5px] rounded-[4px] overflow-hidden items-start relative">
                    <div className="relative w-[96px] mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-medium text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                      {t('fuel-code').replace(/./, x => x.toUpperCase())}
                    </div>
                    <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                      {info.FuelCode}
                    </div>
                  </div>
                  <div className="flex w-full items-start justify-between px-[5px] py-[5px] relative bg-[#f6f7f9] rounded-[4px] overflow-hidden">
                    <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-medium text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                      {t('build-start').replace(/./, x => x.toUpperCase())}
                    </div>
                    <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                      {info.BuildStart}
                    </div>
                  </div>

                </div>
                <div className="inline-flex flex-col items-start gap-[5px] relative flex-[0_0_auto] md:w-1/3 w-full">
                  <div className="flex w-full items-start justify-between px-[5px] py-[5px] relative bg-[#f6f7f9] rounded-[4px] overflow-hidden">
                    <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-medium text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                      {t('build-end').replace(/./, x => x.toUpperCase())}
                    </div>
                    <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                      {info.BuildEnd}
                    </div>
                  </div>
                  <div className="flex w-full justify-between px-[5px] py-[5px] rounded-[4px] overflow-hidden items-start relative">
                    <div className="relative mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-medium text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                      {t('line-start').replace(/./, x => x.toUpperCase())}
                    </div>
                    <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                      {info.LineStart}
                    </div>
                  </div>
                  <div className="flex w-full items-start justify-between px-[5px] py-[5px] relative bg-[#f6f7f9] rounded-[4px] overflow-hidden">
                    <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-medium text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                      {t('line-end').replace(/./, x => x.toUpperCase())}
                    </div>
                    <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                      {info.LineEnd}
                    </div>
                  </div>
                  <div className="flex w-full justify-between px-[5px] py-[5px] rounded-[4px] overflow-hidden items-start relative">
                    <div className="relative mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-medium text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                      {t('apk-end').replace(/./, x => x.toUpperCase())}
                    </div>
                    <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                      {info.Apk_End}
                    </div>
                  </div>
                  <div className="flex w-full items-start justify-between px-[5px] py-[5px] relative bg-[#f6f7f9] rounded-[4px] overflow-hidden">
                    <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-medium text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                      {t('color').replace(/./, x => x.toUpperCase())}
                    </div>
                    <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                      {info.Color}
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className="inline-flex flex-col items-start justify-between relative flex-[0_0_auto] md:w-1/6 h-full">
              {Plate &&
                <div className="flex justify-center p-[10px] relative self-stretch w-full flex-[0_0_auto]">
                  <div className="inline-flex flex-row items-center p-[5px] relative flex-[0_0_auto] bg-[#1359b1] rounded-[3px_0px_0px_3px] overflow-hidden">
                    <div className="relative w-fit [font-family:'Poppins-Bold',Helvetica] font-bold text-white text-[16px] tracking-[0] leading-[24px] whitespace-nowrap">
                      NL
                    </div>
                  </div>
                  <div className="inline-flex flex-row items-center p-[5px] relative flex-[0_0_auto] bg-[#ffc501] rounded-[0px_3px_3px_0px] overflow-hidden">
                    <div className="relative w-fit [font-family:'Poppins-Bold',Helvetica] font-bold text-[#333333] text-[16px] tracking-[0] leading-[normal]">
                      {clearPlate(Plate.toUpperCase())}
                    </div>
                  </div>
                </div>
              }
              <div className="flex p-[10px] justify-center relative self-stretch w-full flex-[0_0_auto]">
                <img className="relative self-stretch w-full flex-[0_0_auto]" style={{ height: "80px" }} src={info.Logo} />
              </div>
              <div className="flex p-[10px] relative  justify-center self-stretch w-full flex-[0_0_auto]">
                {Plate && <RdwLogo onClick={onClickRdwHandler} />}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VehicleInfo;
